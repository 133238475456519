
$brand-primary: #96142d;
$brand-secondary: #556a7f;
$brand-tertiary: #e4ddd7;

$brand-white: #fff;
$brand-black: #000;

$brand-light: #f4f3f2;
$brand-dark: #505050;

$brand-success: #28a745;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
