@import 'Mixins';
@import 'Variables';

@import 'Vendors';

@import 'Base';
@import 'Components';
@import 'Helpers';

.bg_color_main {
  background-color: $brand-light;
}

.bg_color_second {
  // background-color: $brand-secondary;
  background-color: #30455d;
}

.bg_color_main_light {
  background-color: #f5f5f5;
}

.bg_color_main_dark {
  background-color: #d5d7db;
}

.bg_color_tertiary {
  background-color: $brand-tertiary;
}

.bg_color_primary {
  background-color: $brand-primary;
}

.container-fluid {
  overflow: auto; /* safari margin fix */
}

#main {
  flex: 1;
}

.features,
.facts,
.gallery {
  .card {
    &-body {
      padding-bottom: 64px;
    }
  }
}

// solution for section wipes
section {
  position: relative;

  background-color: $white;
}


.bg_color_one {
  background-color: #918779;
}

.bg_color_two {
  background-color: #aba9b7;
}

.bg_color_three {
  background-color: #698e92;
}

.bg_color_four {
  background-color: #a29fa8;
}

.bg_color_five {
  background-color: #648967;
}

.bg_color_six {
  background-color: #4fa6ba;
}

.bg_color_seven {
  background-color: #c6b4a1;
}


// fix for line on Company page (mub)
section {
  overflow: hidden !important;

  margin-bottom: -1px;
}

// hide cookiebot error messages
#root + div {
  display: none;
}
