$font-size-heading-desktop-6: rem(16px);
$font-size-heading-desktop-5: rem(20px);
$font-size-heading-desktop-4: rem(22px);
$font-size-heading-desktop-3: rem(24px);
$font-size-heading-desktop-2: rem(32px);
$font-size-heading-desktop-1: rem(40px);

$line-height-heading-desktop-6: 1.4;
$line-height-heading-desktop-5: 1.4;
$line-height-heading-desktop-4: 1.3;
$line-height-heading-desktop-3: 1.3;
$line-height-heading-desktop-2: 1.3;
$line-height-heading-desktop-1: 1.2;

$font-size-heading-mobile-6: rem(16px);
$font-size-heading-mobile-5: rem(18px);
$font-size-heading-mobile-4: rem(20px);
$font-size-heading-mobile-3: rem(22px);
$font-size-heading-mobile-2: rem(28px);
$font-size-heading-mobile-1: rem(36px);

$line-height-heading-mobile-6: 1.4;
$line-height-heading-mobile-5: 1.4;
$line-height-heading-mobile-4: 1.4;
$line-height-heading-mobile-3: 1.3;
$line-height-heading-mobile-2: 1.3;
$line-height-heading-mobile-1: 1.2;

$font-size-display-desktop-6: rem(44px);
$font-size-display-desktop-5: rem(48px);
$font-size-display-desktop-4: rem(56px);
$font-size-display-desktop-3: rem(72px);
$font-size-display-desktop-2: rem(88px);
$font-size-display-desktop-1: rem(96px);

$line-height-display-desktop-6: 1.2;
$line-height-display-desktop-5: 1.2;
$line-height-display-desktop-4: 1.2;
$line-height-display-desktop-3: 1.2;
$line-height-display-desktop-2: 1.2;
$line-height-display-desktop-1: 1.2;

$font-size-display-mobile-6: rem(28px);
$font-size-display-mobile-5: rem(36px);
$font-size-display-mobile-4: rem(40px);
$font-size-display-mobile-3: rem(48px);
$font-size-display-mobile-2: rem(56px);
$font-size-display-mobile-1: rem(64px);

$line-height-display-mobile-6: 1.3;
$line-height-display-mobile-5: 1.2;
$line-height-display-mobile-4: 1.2;
$line-height-display-mobile-3: 1.2;
$line-height-display-mobile-2: 1.2;
$line-height-display-mobile-1: 1.2;

$font-size-text-sm: rem(14px);
$font-size-text-md: rem(18px);
$font-size-text-lg: rem(24px);

$font-size-input-sm: rem(14px);
$font-size-input-md: rem(18px);
$font-size-input-lg: rem(24px);
$font-size-pagination-sm: rem(16px);
$font-size-pagination-md: rem(20px);
$font-size-pagination-lg: rem(24px);
