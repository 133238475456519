.quotes {
  &.secondary {
    .card {
      border: none;
      background-color: transparent;
      box-shadow: none;

      align-items: center;

      &-body {
        @include media-breakpoint-down(lg) {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .quotes {
        &-text {
          max-width: 530px;

          hyphens: none;
        }
      }

      .teaser {
        font-size: rem(44px);
        line-height: 54.6px;

        position: relative;

        letter-spacing: 0.48px;

        color: $primary;

        @include media-breakpoint-down(xxl) {
          font-size: rem(32px);
          line-height: 43px;
        }
      }

      .preline {
        font-size: rem(24px);
        font-weight: 400;

        margin-top: 30px;

        color: $body-color;

        @include media-breakpoint-down(xxl) {
          font-size: rem(18px);
        }
      }
    }
  }
}
