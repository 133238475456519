.features {
  &.secondary-wall {
    [data-aos] {
      overflow: visible;
    }

    .card {
      transition: all 0.15s ease-in-out;
      transform: scale(1);

      background-color: #30455d;

      // &:hover,
      // &:focus,
      // &:active {
      //   transform: scale(1.05);

      //   background-color: shade-color(#30455d, 20%);
      // }

      .preline {
        color: $white !important;
      }

      .headline {
        font-weight: 400;

        color: $white !important;
      }

      .teaser {
        color: $white !important;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6,
        .display-1,
        .display-2,
        .display-3,
        .display-4,
        .display-5,
        .display-6 {
          color: $white !important;
        }
      }
    }
  }
}
