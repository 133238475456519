.navbar {
  flex-direction: column; // for toplinks
  // min-height: 5rem;

  min-height: 120px;
  // transition: background-color 0.15s ease-in-out;
  // background-image: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.9) 100%);

  background-color: rgba(255, 255, 255, 1);
  box-shadow: $box-shadow-lg, inset 0 rem(1.5px) rem(3px) rgba(0, 0,  0, 0.12);

  justify-content: center; // for toplinks
  @include media-breakpoint-down(md) {
    background-color: rgba(255,255,255, 1) !important;
    .links {
      flex-direction: column;    // need to change position earlier, default is md

      margin-top: 0;

      align-items: flex-start;  // need to change position earlier, default is md

      > * {
        margin-top: 1.25rem;
      }
    }
  }

  > [class*=container] {
    flex-wrap: nowrap;
  }

  &-transparent {
    @include media-breakpoint-up(md) {
      background-color: transparent;
      // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%);
      box-shadow: none;

      .navbar {
        &-brand {
          .img {
            filter: grayscale(1) invert(1) brightness(2);
          }
        }

        &-nav {
          .nav-item {
            .nav-link {
              color: transparentize($white, 0.5);

              &::before {
                background-color: $white;
              }

              &:hover,
              &:focus,
              &:active {
                color: $white;
              }

              &.active {
                color: $white;

                &::before {
                  background-color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  // .toggler {
  //   &-open {
  //     opacity: 0;
  //   }

  //   &-close {
  //     opacity: 1;
  //   }
  // }

  // &-active {

  // }

  &-brand {
    transition: filter 0.45s ease-in-out;
    // flex-shrink: 0;

    flex-shrink: 1;

    .img {
      width: auto;
      // max-height: 3.125rem;
      max-height: 80px;
    }
  }

  &-toggler {
    position: relative;
    z-index: 50;  //over back link

    display: block;

    width: $hamburger-width;
    height: $hamburger-height;
    margin-left: 15px;

    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    border: 0;
    background: transparent;

    flex-shrink: 0;
    @include media-breakpoint-up(md) {
      display: none;
    }

    span {
      position: absolute;
      right: 0;

      display: block;

      width: 100%;
      height: $hamburger-item-heaight;

      -webkit-transition: 0.15s ease-in-out;
      -moz-transition: 0.15s ease-in-out;
      -o-transition: 0.15s ease-in-out;
      transition: 0.15s ease-in-out;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);

      opacity: 1;
      border-radius: 9px;
      background: $hamburger-color;

      &:nth-child(1) {
        top: 2px;

        transform: rotateZ(-45deg);
        transform-origin: right center;
      }

      &:nth-child(2) {
        top: ($hamburger-height /2 ) - $hamburger-item-heaight/2 ;

        width: 0;
      }

      &:nth-child(3) {
        top: $hamburger-height - $hamburger-item-heaight - 2;

        width: 100%;

        transform: rotateZ(45deg);
        transform-origin: right center;
      }
    }

    &.collapsed {
      span {
        &:nth-child(1) {
          top: 5px;

          transform: none;
        }

        &:nth-child(2) {
          top: ($hamburger-height /2 ) - $hamburger-item-heaight/2 ;

          width: 72%;
        }

        &:nth-child(3) {
          top: $hamburger-height - $hamburger-item-heaight - 5;

          width: 85%;

          transform: none;
        }
      }
    }
  }

  &-collapse {
    @include media-breakpoint-down(md) {
      position: fixed;
      z-index: 1;
      // top: 80px;                                     // 80px - height of navbar
      top: 120px;
      right: 0;
      // bottom: 0;
      left: 0;
      // height: 100%;

      border-top: 1px solid $gray-400 ;
      background: $white;
    }

    &-inner {
      display: flex;

      width: 100%;

      justify-content: flex-end;
      align-items: center;
      @include media-breakpoint-down(md) {
        overflow: scroll;
        flex-direction: column;

        max-width: 720px;
        // height: calc(100vh - 80px);                 // 80px - height of navbar
        height: calc(100vh - 120px);
        margin: 0 auto;
        padding-top: rem(32px);
        padding-right: var(--bs-gutter-x, 0.75rem); // this, above and margin + max-width are to mimic .container
        padding-bottom: rem(32px);                  // can't set padding on collapse (leads to a broken animation)
        padding-left: var(--bs-gutter-x, 0.75rem);

        align-items: stretch;
        justify-content: flex-start;
      }
      @include media-breakpoint-down(md) {
        max-width: 540px;
      }
    }
  }

  &-nav {
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
      .dropdown {
        &-menu {
          margin-top: 0;
          padding: 0;

          border: none;
          box-shadow: none;
        }

        li {
          &:last-child {
            padding-bottom: 1.25rem;              // need to add it to last li in dropdown to make equal space on top and bottom
          }
        }

        &-item {
          padding: 0.75rem 1rem;
        }

        &-toggle {
          &::after,
          &::before {
            position: absolute;
            top: 50%;
            right: 0;
            left: auto  !important;             // overriding Bootstrap to set right position to ::before

            display: block !important;          // overriding Bootstrap to always show ::before

            width: 20px;
            height: 2px;
            margin-top: -1px;                   // because we use transform to rotate it's better to use margin-top with height / 2 here

            content: '';

            border: none;
            background-color: $dark;
          }

          &::before {
            transition: transform 0.15s ease-in-out;
            transform: rotateZ(90deg);
          }

          &.show {
            &::before {
              transform: rotateZ(0);
            }
          }
        }
      }

      .nav-item {
        border-bottom: 1px solid $gray-400;
      }

      .nav-link {
        padding: 1.25rem 0;

        &:hover,
        &:focus,
        &:active {
          &::before {
            display: none;
          }
        }
      }
    }

    .nav {
      &-item {
        &.dropdown {
          @include media-breakpoint-down(md) {
            display: block;
          }

          .nav-link {
            @include media-breakpoint-up(md) {
              padding-right: 0 !important;
            }
          }

          .dropdown-toggle {
            &::after {
              font-size: 15px; // fix for cutting off the tip of caret

              margin-bottom: -3px;
              // display: none;

              color: #9c9c9c;
            }

            &-split {
              @include media-breakpoint-down(md) {
                position: absolute;
                // top: 50%;
                top: 25px;
                right: 0;

                padding: 20px;

                transform: translateY(-50%);
              }
            }
          }
        }

        .nav-link {
          font-size: rem(16px);

          text-transform: uppercase;

          color: transparentize($body-color, 0.5);

          &:hover,
          &:focus,
          &:active {
            &::before {
              right: 19px;
              left: 19px;
            }
          }

          &.active {
            color: $body-color;

            @include media-breakpoint-up(md) {
              &::before {
                position: absolute;
                right: 19px;
                bottom: 0;
                left: 19px;

                display: block;

                height: 2px;

                content: '';

                background-color: $primary;
              }
            }
          }
        }
      }
    }
  }

  &-toplinks {
    width: 100%;
    margin-top: -0.5rem; // navbar padding top/bottom
    margin-bottom: 10px;
    padding: 12px 0;

    background-color: #f5f5f5;

    @include media-breakpoint-down(md) {
      margin-top: 26px;
    }
    @include media-breakpoint-up(md) {
      + .container {
        padding: 8px 0; // to add more height to the main navbar when toplinks are present
      }
    }

    .toplinks {
      display: flex;

      margin-bottom: 0;
      padding: 0;

      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      @include media-breakpoint-down(md) {
        flex-direction: column;

        align-items: flex-start;
      }

      &-link {
        font-size: rem(12px);
        font-weight: $font-weight-semi-bold;

        transition: color 0.15s ease-in-out;

        color: $brand-dark;

        flex-shrink: 0;
        @include media-breakpoint-down(md) {
          margin-left: 0 !important;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }


        &:not(:first-child) {
          margin-left: 23px;
        }

        &:hover,
        &:focus,
        &:active {
          color: shade-color($brand-dark, 25%);
        }
      }
    }
  }

  &-buttons {
    display: flex;

    align-items: center;
  }

  &-search {
    transition: color 0.15s ease-in-out;

    color: $brand-dark;
    border: none;
    background-color: transparent;

    appearance: none;

    &-wrapper {
      margin-left: 15px;
      padding: 5px;

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      color: shade-color($brand-dark, 25%);
    }

    &-modal {
      .modal-dialog {
        @include media-breakpoint-up(sm) {
          width: 100%;
          max-width: 870px;
        }
      }

      .modal-content {
        padding: 56px 32px;
      }

      .modal-close {
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }

    &-form {
      .headline {
        font-weight: $font-weight-semi-bold;

        margin-bottom: 16px;
      }

      .search {
        position: relative;

        &-input {
          padding-right: 40px;
        }

        &-button {
          position: absolute;
          top: 50%;
          right: 25px;

          margin-left: 15px;
          padding: 5px;

          transform: translate(50%, -50%);

          @extend .navbar-search;
        }
      }
    }
  }
}
