.text {
  &-sm {
    font-size: $font-size-text-sm;
  }

  &-lg {
    font-size: $font-size-text-lg;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-heading-mobile-3;
    }
  }
}
